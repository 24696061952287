export const LAYOUT_TYPE_STANDARD_WEBSITE = "standard_website";
export const LAYOUT_TYPE_WEB_APP = "web_app";

// Site level setting key
export const SITE_SETTING_LAYOUT = "layout";
export const SITE_SETTING_ANALYTICS = "analytics";
export const SITE_SETTING_LANGUAGE = "language";
export const SITE_SETTING_APP = "app";
export const SITE_SETTING_METADATA = "metadata";
export const SITE_SETTING_COMPANY = "company";
export const SITE_SETTING_CONTACT = "contact";
export const SITE_SETTING_CHAT = "chat";
export const SITE_SETTING_THEME = "theme";
export const SITE_SETTING_SERVICES = "services";
export const SITE_SETTING_SITEMAP = "sitemap";
export const SITE_SETTING_REALM = "realm";
export const SITE_SETTING_SIGNUP = "signup";
export const SITE_SETTING_PAYMENT = "payment";
export const SITE_SETTING_ADDRESS = "address";
export const SITE_SETTING_POINTS = "points";
export const SITE_SETTING_CREDITS = "credits";
export const SITE_SETTING_MEMBER = "member";
export const SITE_SETTING_REFERRAL = "referral";

// Standard pages (Direct under lang)
export const PAGE_ID_HOME = "home";
export const PAGE_ID_DYNAMIC_HOME = "dynamic-home";
export const PAGE_ID_SIGNUP = "signup";
export const PAGE_ID_CHECKOUT = "checkout";
export const PAGE_ID_PRICING = "pricing";
export const PAGE_ID_ACCOUNT = "account";
export const PAGE_ID_ADDRESS = "address";
export const PAGE_ID_PROMOTION = "promotion";
export const PAGE_ID_LOCATION = "location";
export const PAGE_ID_CONTACT_US = "contact-us";
export const PAGE_ID_ABOUT_US = "about-us";
export const PAGE_ID_FAQS = "faqs";
export const PAGE_ID_BLOGS = "blogs";
export const PAGE_ID_REVIEWS = "reviews";
export const PAGE_ID_CAREERS = "join-us";
export const PAGE_ID_MEMBER = "member";
export const PAGE_ID_ADVANCE_CREDIT = "credit";
export const PAGE_ID_REWARD_POINTS = "points";
export const PAGE_ID_PRIVACY_POLICY = "privacy-policy";
export const PAGE_ID_TERMS_OF_SERVICE = "terms-of-service";
export const PAGE_ID_REFERRAL = "referral";
export const PAGE_ID_TRACKING = "tracking";
export const PAGE_ID_ORDERS = "orders";
export const PAGE_ID_NOTIFICATION = "notification";
export const PAGE_ID_CASHIER = "cashier";
export const PAGE_ID_CONFIRMATION = "confirmation";
export const PAGE_ID_ORDER_DETAIL = "order";
export const PAGE_ID_RESCHEDULE = "reschedule";

export const STANDARD_PAGES = [
  PAGE_ID_HOME,
  PAGE_ID_SIGNUP,
  PAGE_ID_PRICING,
  PAGE_ID_CHECKOUT,
  PAGE_ID_CONFIRMATION,
  PAGE_ID_CASHIER,
  PAGE_ID_TRACKING,
  PAGE_ID_ACCOUNT,
  PAGE_ID_ORDERS,
  PAGE_ID_ORDER_DETAIL,
  PAGE_ID_ADDRESS,
  PAGE_ID_ADVANCE_CREDIT,
  PAGE_ID_REWARD_POINTS,
  PAGE_ID_MEMBER,
  PAGE_ID_PROMOTION,
  PAGE_ID_LOCATION,
  PAGE_ID_CONTACT_US,
  PAGE_ID_ABOUT_US,
  PAGE_ID_FAQS,
  PAGE_ID_BLOGS,
  PAGE_ID_REVIEWS,
  PAGE_ID_CAREERS,
  PAGE_ID_REFERRAL,
  PAGE_ID_NOTIFICATION,
  PAGE_ID_PRIVACY_POLICY,
  PAGE_ID_TERMS_OF_SERVICE,
];

// Internal pages
export const PAGE_ID_SIGN_IN = "sign-in";
export const PAGE_ID_FORGOT_PASSWORD = "forgot-password";
export const PAGE_ID_DEVICE_PAIRING = "device-pairing";
export const PAGE_ID_DEVICE_REGISTER = "device-register";
export const PAGE_ID_LICENSE_EXPIRED = "license-expired";
export const PAGE_ID_BILLING = "billing";
export const PAGE_ID_BILLING_HISTORY = "billing-history";
export const PAGE_ID_MY_JOBS = "my-jobs";
export const PAGE_ID_SERVICE_CENTER = "service-center";
export const PAGE_ID_STORE = "store";
export const PAGE_ID_INBOX = "inbox";
export const PAGE_ID_SCAN_DETECT = "scan-detect";

export const INTERNAL_STANDARD_PAGES = [PAGE_ID_MY_JOBS, PAGE_ID_SERVICE_CENTER, PAGE_ID_STORE, PAGE_ID_INBOX];

export const LINK_TYPE_PAGE = "page";
export const LINK_TYPE_HTTP = "http";
export const LINK_TYPE_WHATSAPP = "whatsapp";
export const LINK_TYPE_EMAIL = "email";
export const LINK_TYPE_TEL = "tel";
export const LINK_TYPE_LOCATION = "location";
export const LINK_TYPE_GOOGLE_PLAY = "google_play";
export const LINK_TYPE_APP_STORE = "app_store";
export const LINK_TYPE_OTP = "otp";

export const SIGNUP_SIMPLE = "simple";
export const SIGNUP_WITH_SPLIT_SCREEN = "with-split-screen";

export const PAGE_SECTION_HERO = "hero";
export const PAGE_SECTION_HEADER = "header";
export const PAGE_SECTION_FEATURES = "features";
export const PAGE_SECTION_INCENTIVES = "incentives";
export const PAGE_SECTION_REVIEWS = "reviews";
export const PAGE_SECTION_TESTIMONIALS = "testimonials";
export const PAGE_SECTION_STATS = "stats";
export const PAGE_SECTION_LOGO = "logo";
export const PAGE_SECTION_FAQS = "faqs";
export const PAGE_SECTION_CTA = "cta";
export const PAGE_SECTION_CONTACT = "contact";
export const PAGE_SECTION_PROMOTION = "promotion";
export const PAGE_SECTION_TEAM = "team";
export const PAGE_SECTION_BLOGS = "blogs";
export const PAGE_SECTION_GENERAL = "general";
export const PAGE_SECTION_CONTENT = "content";

// Cloud App page sections
export const PAGE_SECTION_JOB = "job";

export const COLOR_900 = "900";
export const COLOR_800 = "800";
export const COLOR_700 = "700";
export const COLOR_600 = "600";
export const COLOR_500 = "500";
export const COLOR_400 = "400";
export const COLOR_300 = "300";
export const COLOR_200 = "200";
export const COLOR_100 = "100";
export const COLOR_50 = "50";

export const COLOR_PRIMARY = "primary";
export const COLOR_ACCENT = "accent";
export const COLOR_THIRD = "third";
export const COLOR_WHITE = "white";
export const COLOR_BLACK = "black";
export const COLOR_TRANSPARENT = "transparent";
//
export const COLOR_SLATE = "slate";
export const COLOR_GRAY = "gray";
export const COLOR_ZINC = "zinc";
export const COLOR_NEUTRAL = "neutral";
export const COLOR_STONE = "stone";
export const COLOR_RED = "red";
export const COLOR_ORANGE = "orange";
export const COLOR_AMBER = "amber";
export const COLOR_YELLOW = "yellow";
export const COLOR_LIME = "lime";
export const COLOR_GREEN = "green";
export const COLOR_EMERALD = "emerald";
export const COLOR_TEAL = "teal";
export const COLOR_CYAN = "cyan";
export const COLOR_SKY = "sky";
export const COLOR_BLUE = "blue";
export const COLOR_INDIGO = "indigo";
export const COLOR_VIOLET = "violet";
export const COLOR_PURPLE = "purple";
export const COLOR_FUCHSIA = "fuchsia";
export const COLOR_PINK = "pink";
export const COLOR_ROSE = "rose";

export const ICON_TYPE_OUTLINE = "outline";
export const ICON_TYPE_SOLID = "solid";
export const ICON_TYPE_SVG = "svg";
export const ICON_TYPE_IMG = "img";

export const SVG_FACEBOOK = "facebook";
export const SVG_INSTAGRAM = "instagram";
export const SVG_X = "twitter";
export const SVG_YOUTUBE = "youtube";
export const SVG_WHATSAPP = "whatsapp";
export const SVG_GOOGLE = "google";
export const SVG_GOOGLE_PLAY = "google_play";
export const SVG_APP_STORE = "app_store";
export const SVG_DOT = "dot";
export const SVG_QUOTE = "quote";
export const SVG_QUOTE_OUTLINE = "quote_outline";
export const SVG_EMPTY_BLOCK = "empty_block";
export const SVG_CHECK_MARK = "check_mark";

export const ANIMATION_STAGGER = "stagger";
export const ANIMATION_FADE_IN_BOTTOM = "fade-in-bottom";

export const DECORATION_GRID_FROM_TOP_RIGHT = "grid_from-top-right";
export const DECORATION_GRID_FROM_BOTTOM_CENTER = "grid_from-bottom-center";
export const DECORATION_GRID_FROM_TOP_RIGHT_WITH_COLOR_TILES = "grid_from-top-right-with-color-tiles";
export const DECORATION_SHADOW_SLASH_MIDDLE = "shadow-slash_middle";
export const DECORATION_SHADOW_SLASH_ON_LEFT = "shadow-slash_on-left";
export const DECORATION_SHADOW_SLASH_FULL = "shadow-slash_full";
export const DECORATION_COLOR_GRADIENT_TOP_LEFT_TO_BOTTOM_RIGHT = "color-gradient_top-left-to-bottom-right";
export const DECORATION_COLOR_GRADIENT_TOP_LEFT = "color-gradient_top-left";
export const DECORATION_COLOR_GRADIENT_FAR_RIGHT = "color-gradient_right";
export const DECORATION_COLOR_GRADIENT_BOTTOM_TOP_RIGHT = "color-gradient_bottom-top-right";
export const DECORATION_COLOR_GRADIENT_BOTTOM_CENTER = "color-gradient_bottom-center";
export const DECORATION_COLOR_GRADIENT_BETWEEN = "color-gradient_between";
export const DECORATION_COLOR_GRADIENT_CENTER_1 = "color-gradient_center-1";
export const DECORATION_COLOR_GRADIENT_CENTER_2 = "color-gradient_center-2";
export const DECORATION_COLOR_GRADIENT_CENTER_3 = "color-gradient_center-3";
export const DECORATION_COLOR_GRADIENT_CENTER_4 = "color-gradient_center-4";
export const DECORATION_WAVE = "wave";
// All other color gradient variations are named as `color-gradient-<number>`

// Components (Raw)
export const COMPONENT_PAGE = "page";
export const COMPONENT_CONTAINER = "container";
export const COMPONENT_TEXT = "text";
export const COMPONENT_PLAIN_TEXT = "plain_text";
export const COMPONENT_IMAGE = "image";
export const COMPONENT_ICON = "icon";
export const COMPONENT_BUTTON = "button";
export const COMPONENT_DIVIDER = "divider";
export const COMPONENT_TEXT_FIELD = "text_field";
export const COMPONENT_PHONE_NUMBER_FIELD = "phone_number_field";
export const COMPONENT_FLYOUT_MENUS = "flyout_menus";
export const COMPONENT_DRAWER_MENUS = "drawer_menus";
export const COMPONENT_GOOGLE_PLAY_BADGE = "google_play_badge";
export const COMPONENT_APP_STORE_BADGE = "app_store_badge";
export const COMPONENT_CAROUSELS = "carousels";
export const COMPONENT_PRODUCT = "product";
export const COMPONENT_ACTIVITY_ALERT = "activity_alert";
export const COMPONENT_STAR_RATING = "star_rating";
export const COMPONENT_MENU = "menu";
export const COMPONENT_YOUTUBE_EMBED = "youtube_embed";
export const COMPONENT_TRACKING_PANEL = "tracking_panel";

// Components (Composed)
export const COMPONENT_NOT_FOUND = "not_found";
export const COMPONENT_SIGNUP_FORM = "signup_form";
export const COMPONENT_REVIEW_GRID_ANIMATED = "review_grid_animated";
export const COMPONENT_TIMELINE = "timeline";
export const COMPONENT_TESTIMONIALS_GRID = "testimonials_grid";
export const COMPONENT_MOBILE_APP_ILLUSTRATION = "mobile_app_illustration";
export const COMPONENT_IMAGE_TILES = "image_tiles";
export const COMPONENT_IMAGE_TILES_DIFFERENT_LARGE = "image_tiles_different_large";
export const COMPONENT_IMAGE_TILES_SQUARE = "image_tiles_square";
export const COMPONENT_IMAGE_TILES_DIAMOND = "image_tiles_diamond";
export const COMPONENT_REVIEW_SUMMARY = "review_summary";
export const COMPONENT_CONTENT_WITH_ANGLED_IMAGE = "COMPONENT_CONTENT_WITH_ANGLED_IMAGE";
export const COMPONENT_PRODUCT_GRID_WITH_CATEGORY = "product_grid_with_category";
export const COMPONENT_PRODUCT_GRID = "product_grid";
export const COMPONENT_SERVICE_TYPE_PRICING_GRID = "service_type_pricing_grid";
export const COMPONENT_FEEDBACK = "feedback";
export const COMPONENT_PAYMENT_FORM = "payment_form";
export const COMPONENT_CASHIER = "cashier";
export const COMPONENT_CONFIRMATION_STATUS = "confirmation_status";
export const COMPONENT_CONFIRMATION_CONTINUE = "payment_confirmation_continue";
export const COMPONENT_INVOICE = "invoice";
export const COMPONENT_INVOICE_MEMO = "invoice_memo";
export const COMPONENT_INVOICE_PRINT_BUTTON = "invoice_print_button";
export const COMPONENT_PROMOTION_BANNER = "promotion_banner";

// Account components
export const COMPONENT_SIGN_IN_ACCOUNT = "sign_in_account";
export const COMPONENT_ORDER_HISTORY_LIST = "order_history_list";
export const COMPONENT_ADDRESS_LIST = "address_list";
export const COMPONENT_PAYMENT_METHODS_LIST = "payment_methods_list";
export const COMPONENT_ADDRESS_FORM = "address_form";
export const COMPONENT_CUSTOMER_PROFILE = "customer_profile";
export const COMPONENT_NOTIFICATION_LIST = "notification_list";

// Checkout components
export const COMPONENT_MULTI_STEP_CHECKOUT = "multi_step_checkout";
export const COMPONENT_CHECKOUT_PAYMENT_PREVIEW = "checkout_payment_preview";
export const COMPONENT_NEW_ORDER_PREVIEW = "new_order_preview";
export const COMPONENT_CHECKOUT_STEPS_OVERVIEW = "checkout_steps_overview";
export const COMPONENT_CHECKOUT_NEXT_BUTTON = "checkout_next_button";
export const COMPONENT_CHECKOUT_BACK_BUTTON = "checkout_back_button";
export const COMPONENT_CHECKOUT_TBD_PAYMENT_NOTE = "checkout_tbd_payment_note";
export const COMPONENT_CHAT_WIDGET = "chat_widget";

// Order detail components
export const COMPONENT_ORDER_PAYMENTS = "order_payments";
export const COMPONENT_ORDER_QR_CODE = "order_qr_code";
export const COMPONENT_ITEM_QR_CODE = "item_qr_code";
export const COMPONENT_PACKAGE_QR_CODE = "package_qr_code";
export const COMPONENT_ORDER_DETAIL_SERVICE_NAME = "order_detail_service_name";
export const COMPONENT_ORDER_DETAIL_ORDER_NO = "order_detail_order_no";
export const COMPONENT_ORDER_DETAIL_PROGRESS_BAR = "order_detail_progress_bar";
export const COMPONENT_ORDER_DETAIL_CONTAINER = "order_detail_container";
export const COMPONENT_ORDER_DETAIL_CANCEL_BUTTON = "order_detail_cancel_button";
export const COMPONENT_ORDER_DETAIL_SUMMARY = "order_detail_summary";
export const COMPONENT_ORDER_DETAIL_ACTIVITY = "order_detail_activity";
export const COMPONENT_ORDER_DETAIL_BOOKING = "order_detail_booking";

// Container subTypes
export const COMPONENT_HEADER_MENUS = "header_menus";
export const COMPONENT_SECTION = "section";
export const COMPONENT_ACCORDION = "accordion";
export const COMPONENT_TABS = "tabs";
export const COMPONENT_FORM = "form";
export const COMPONENT_LIST = "list";
export const COMPONENT_SOCIAL_MEDIAS = "social_medias";
export const COMPONENT_SERVICE_TYPE_PRICING = "service_type_pricing";
export const COMPONENT_SERVICE_TYPE_PRICE_OPTION = "service_type_price_option";
export const COMPONENT_SERVICE_TYPE_OPTION = "service_type_option";
export const COMPONENT_NAVBAR = "navbar";
export const COMPONENT_NAVBAR_MENUS = "navbar_menus";
export const COMPONENT_SERVICES_SELECT_PANEL = "services_select_panel";
export const COMPONENT_CUSTOMER_REFERRAL = "customer_referral";
export const COMPONENT_CHECKOUT_BUSINESS_GRID = "checkout_business_grid";
export const COMPONENT_CHECKOUT_BUSINESS_CARD = "checkout_business_card";
export const COMPONENT_CHECKOUT_BOOKING_PANEL = "checkout_booking_panel";
export const COMPONENT_SIGN_IN_ALERT = "sign_in_alert";
export const COMPONENT_LINE_ITEMS = "line_items";
export const COMPONENT_ADJUSTMENT_ITEMS = "adjustment_items";
export const COMPONENT_LINE_ITEM_PHOTO = "line_item_photo";

// Chat widget
export const COMPONENT_CHAT_WIDGET_BUTTON = "chat_widget_button";
export const COMPONENT_CHAT_WIDGET_HOME = "chat_widget_home";
export const COMPONENT_CHAT_WIDGET_CLOSE = "chat_widget_close";
export const COMPONENT_CHAT_WIDGET_TEAM_AVATARS = "chat_widget_team_avatars";
export const COMPONENT_CHAT_WIDGET_CONTACT_CHANNELS = "chat_widget_contact_channels";
export const COMPONENT_CHAT_WIDGET_CONVERSATION = "chat_widget_conversation";

// Image subTypes
export const COMPONENT_LOGO = "logo";
export const COMPONENT_PAYNOW_MANUAL_QR = "paynow_manual_qr";
export const COMPONENT_PAYNOW_STRIPE_QR = "paynow_stripe_qr";

// Text display subTypes
export const COMPONENT_ADDRESS_FORM_TITLE = "address_form_title";

// Components display types (Not real component, just for title display)
export const COMPONENT_HEADER = "header";
export const COMPONENT_FOOTER = "footer";
export const COMPONENT_BANNER = "banner";
export const COMPONENT_ACCORDION_HEADER = "accordion_header";
export const COMPONENT_ACCORDION_BODY = "accordion_body";
export const COMPONENT_COPYRIGHT_NOTICE = "copyright_notice";

export const CHECKOUT_STEP_SERVICES = "services";
export const CHECKOUT_STEP_ADDRESS = "address";
export const CHECKOUT_STEP_DATE_TIME = "date_time";
export const CHECKOUT_STEP_CONTACT = "contact";
export const CHECKOUT_STEP_PAYMENT = "payment";
export const CHECKOUT_STEP_PHOTO = "photo";

export const COMPONENT_MEMBERSHIP_PANEL = "membership";
export const COMPONENT_ADVANCE_CREDIT_BALANCE = "advance_credit";
export const COMPONENT_ADVANCE_CREDIT_HISTORY = "advance_credit_history";
export const COMPONENT_REWARD_POINTS_BALANCE = "reward_points";
export const COMPONENT_REWARD_POINTS_HISTORY = "points_history";

export const TEMPLATE_CATEGORY_EMAIL = "email";
export const TEMPLATE_CATEGORY_WHATSAPP = "whatsapp";
export const TEMPLATE_CATEGORY_PDF = "pdf";
export const TEMPLATE_CATEGORY_SPREADSHEET = "spreadsheet";
export const TEMPLATE_CATEGORY_RECEIPT = "receipt";
export const TEMPLATE_CATEGORY_LABEL = "label";
export const TEMPLATE_CATEGORY_IMAGE = "image";
export const TEMPLATE_CATEGORY_WEBSITE = "website";
export const TEMPLATE_CATEGORY_WORKFLOW = "workflow";
export const TEMPLATE_CATEGORY_CLOUD_APP = "cloud_app";

// Font
export const FONT_WEIGHT_EXTRALIGHT = "extralight"; // 200
export const FONT_WEIGHT_LIGHT = "light"; // 300
export const FONT_WEIGHT_NORMAL = "normal"; // 400
export const FONT_WEIGHT_MEDIUM = "medium"; // 500
export const FONT_WEIGHT_SEMIBOLD = "semibold"; // 600
export const FONT_WEIGHT_BOLD = "bold"; // 700

export const TEMPLATE_STATUS_APPROVED = "APPROVED";
export const TEMPLATE_STATUS_ACTIVE = "ACTIVE";
export const TEMPLATE_STATUS_REJECTED = "REJECTED";
export const TEMPLATE_STATUS_PENDING = "PENDING";

// Predefined templates
export const TEMPLATE_ID_FOOTER = `footer`;
export const TEMPLATE_ID_OTP = `otp`;
// TODO: delete all template_id below
export const TEMPLATE_ID_WELCOME = `welcome`;
export const TEMPLATE_ID_ORDER_PLACED_POS = `new_order_pos`;
export const TEMPLATE_ID_ORDER_PLACED_ONLINE = `new_order_online`;
export const TEMPLATE_ID_PICK_UP_FAILED = `pick_up_failed`;
export const TEMPLATE_ID_PICKED_UP = `picked_up`;
export const TEMPLATE_ID_PICK_UP_RESCHEDULED = `pick_up_rescheduled`;
export const TEMPLATE_ID_DELIVER_FAILED = `deliver_failed`;
export const TEMPLATE_ID_DELIVER_RESCHEDULED = `deliver_rescheduled`;
export const TEMPLATE_ID_DELIVERED = `delivered`;
export const TEMPLATE_ID_ORDER_READY_FOR_COLLECTION = `ready_for_collection`;
export const TEMPLATE_ID_ORDER_COLLECTED = `collected`;
export const TEMPLATE_ID_ORDER_CANCELLED = `cancelled`;
export const TEMPLATE_ID_ORDER_INVOICE = `order_invoice`;
export const TEMPLATE_ID_ORDER_RECEIPT = `order_receipt`;
export const TEMPLATE_ID_TOP_UP_RECEIPT = `top_up_receipt`;
export const TEMPLATE_ID_MEMBER_RECEIPT = `member_receipt`;

// Cloud App components
export const COMPONENT_MY_JOBS = "my_jobs";

export const COMPONENT_RECEIPT = "receipt";
export const COMPONENT_LABEL = "label";
export const COMPONENT_CUSTOMER_SIGNATURE = "customer_signature";
export const COMPONENT_TEXT_COLUMNS = "text_columns";
export const COMPONENT_LINE_FEED = "line_feed";
export const COMPONENT_DASH_LINE = "dash_line";
