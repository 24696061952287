"use client";

import { createContext, useContext, useMemo, useState } from "react";

const ServerFunctionContext = createContext();

export function ServerFunctionProvider({ callable, children }) {
  const [blockingRequest, setBlockingRequest] = useState(null);

  const context = useMemo(() => {
    return {
      callable,
      blockingRequest,
      setBlockingRequest,
    };
  }, [callable, blockingRequest]);

  return <ServerFunctionContext.Provider value={context}>{children}</ServerFunctionContext.Provider>;
}

export const useServerFunctionCall = () => useContext(ServerFunctionContext).callable;
export const useServerFunctionSyncRequest = () => useContext(ServerFunctionContext).blockingRequest;
export const useServerFunctionSyncSetRequest = () => useContext(ServerFunctionContext).setBlockingRequest;
