import { ArrowUpOutlined } from "@ant-design/icons";
import { useCheckoutCustomerSetter, useUserBusinessCode, useUserRealmId } from "@easybiz/shell";
import { LoadMoreButton } from "@easybiz/web-admin";
import { Button, Empty, List } from "antd";
import dayjs from "dayjs";
import {
  collection,
  doc,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import CustomerListItem from "./CustomerListItem";

export default ({ search, onSearch }) => {
  const realmId = useUserRealmId();
  const setCustomer = useCheckoutCustomerSetter();
  const businessCode = useUserBusinessCode();
  const [args, setArgs] = useState({ limit: 5 });
  const [customers, setCustomers] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (search) {
      setArgs({ search, limit: 5 });
    }
  }, [search]);

  useEffect(() => {
    if (realmId) {
      let operation = query(collection(getFirestore(), `realms/${realmId}/customers`), limit(args.limit));

      if (search) {
        operation = query(
          operation,
          where(`searchables`, `array-contains`, `${search}`.trim().toLowerCase()),
          orderBy("viewDate", "desc")
        );
      } else if (businessCode) {
        operation = query(operation, orderBy(`lastVisit.${businessCode}`, "desc"));
      }

      setLoading(true);
      return onSnapshot(operation, (result) => {
        setCustomers(result.docs);
        setLoading(false);
      });
    }
  }, [realmId, args, businessCode]);

  const onSelectCustomer = useCallback(
    (customer) => {
      setCustomer(customer);
      onSearch(null);

      updateDoc(doc(getFirestore(), `realms/${realmId}/customers/${customer.id}`), {
        viewDate: new Date(),
        lastVisit: {
          [businessCode]: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        },
      });
    },
    [realmId, businessCode]
  );

  return (
    <List
      className={"padding-horizontal-half"}
      loading={!customers}
      header={
        <div className="flex flex-row flex-align-center flex-space-between">
          <FormattedMessage defaultMessage={"Click to select customer account"} />
          <Button size="small" icon={<ArrowUpOutlined />} type="link" onClick={() => onSearch(null)}>
            <FormattedMessage tagName={'span'} defaultMessage={"Close"} />
          </Button>
        </div>
      }
      dataSource={customers || []}
      renderItem={(customer) => <CustomerListItem customer={customer} onSelect={onSelectCustomer} />}
      search={search}
      locale={
        search
          ? {
              emptyText: (
                <Empty
                  description={
                    <FormattedMessage
                      tagName="span"
                      defaultMessage='No customer found by searching "{search}"'
                      values={{ search: <b>{search}</b> }}
                    />
                  }
                />
              ),
            }
          : undefined
      }
      footer={
        customers &&
        customers.length >= 5 && (
          <LoadMoreButton
            loading={loading}
            dataSource={customers}
            onClick={() => setArgs({ ...args, limit: args.limit + 5 })}
          />
        )
      }
    />
  );
};
