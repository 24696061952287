"use client";

import { useClientCustomerScreenSetter, useClientInstallId, useUser, useUserRealmId } from "@easybiz/shell";
import { useEffect } from "react";

export default function ConnectDeviceMonitor({ doc, getFirestore, onSnapshot }) {
  const realmId = useUserRealmId();
  const user = useUser();
  const installId = useClientInstallId();
  const customerScreenId = user?.connectDeviceId || installId;
  const setConnectDevice = useClientCustomerScreenSetter();

  useEffect(() => {
    if (realmId && customerScreenId) {
      return onSnapshot(
        doc(getFirestore(), `realms/${realmId}/customer_screens/${customerScreenId}`),
        setConnectDevice
      );
    }
  }, [realmId, customerScreenId]);

  return null;
}
