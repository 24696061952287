"use client";

import { useClientInstallId, useFeedbackToast, useUserIsPendingSignIn } from "@easybiz/shell";
import { useEffect } from "react";
import { useIntl } from "react-intl";

export default function LoggedOutDeviceAutoSignIn({
  onSnapshot,
  doc,
  getFirestore,
  signInWithCustomToken,
  getAuth,
  deleteDoc,
}) {
  const installId = useClientInstallId();
  const isPendingSignIn = useUserIsPendingSignIn();
  const toast = useFeedbackToast();
  const intl = useIntl();

  useEffect(() => {
    if (installId && isPendingSignIn) {
      return onSnapshot(doc(getFirestore(), `devices/${installId}`), (device) => {
        if (device.get("token")) {
          signInWithCustomToken(getAuth(), device.get("token")).catch(() => {
            toast.error(intl.formatMessage({ id: "token.expired", defaultMessage: "Token expired" }));
            deleteDoc(device.ref);
          });
        }
      });
    }
  }, [installId, isPendingSignIn]);
}
