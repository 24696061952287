"use client";

import { createContext, useContext, useMemo, useState } from "react";

const FeedbackContext = createContext();

export function FeedbackProvider({ children, toaster }) {
  const [state, setState] = useState(null);

  const context = useMemo(() => {
    const toast = (message, status) => {
      setState({
        ...(typeof message === "string" ? { title: message } : message),
        ...status,
      });
    };

    return {
      state,
      toast: toaster || {
        error: (message) => toast(message, { error: true }),
        success: (message) => toast(message, { success: true }),
        info: (message) => toast(message, { info: true }),
      },
      onClose: () => setState(null),
    };
  }, [state, toaster]);

  return <FeedbackContext.Provider value={context}>{children}</FeedbackContext.Provider>;
}

export const useFeedbackToast = () => useContext(FeedbackContext).toast;
export const useFeedbackState = () => useContext(FeedbackContext).state;
export const useFeedbackClose = () => useContext(FeedbackContext).onClose;
