import { ShopFilled } from "@ant-design/icons";
import { NameDisplay } from "@easybiz/component";
import { useClientOTAUpdate, useUserBusinessCode } from "@easybiz/shell";
import { Badge, Button } from "antd";
import { useIntl } from "react-intl";

export default function ({ onClick }) {
  const businessCode = useUserBusinessCode();
  const haveUpdate = useClientOTAUpdate();
  const intl = useIntl();

  return (
    <Badge
      count={
        haveUpdate
          ? intl.formatMessage({
              defaultMessage: "New version",
            })
          : null
      }
    >
      <Button icon={<ShopFilled />} onClick={onClick}>
        <span>
          <NameDisplay nameKey={businessCode} />
        </span>
      </Button>
    </Badge>
  );
}
