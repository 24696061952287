"use client";

import { useActionCallback } from "@easybiz/hook";
import { useFeedbackToast, useRealmPOSSignUpFields } from "@easybiz/shell";
import {
  ACCOUNT_FIELD_EMAIL,
  ACCOUNT_FIELD_NAME,
  ACCOUNT_FIELD_PHONE_NUMBER,
  CUSTOMER_ACTION_PROFILE_SAVE,
  OPERATION_GROUP_CUSTOMER,
  isValidEmail,
} from "@easybiz/utils";
import { useCallback } from "react";
import { useIntl } from "react-intl";

function useCustomerProfileSave(callback) {
  const signUpFields = useRealmPOSSignUpFields();
  const askName = signUpFields?.includes(ACCOUNT_FIELD_NAME);
  const askTel = signUpFields?.includes(ACCOUNT_FIELD_PHONE_NUMBER);
  const askEmail = signUpFields?.includes(ACCOUNT_FIELD_EMAIL);
  const toast = useFeedbackToast();
  const intl = useIntl();

  const onSubmit = useCallback(
    ({
      name,
      email,
      phoneNumber,
      avatar,
      customerId,
      editOrderId,
      remarks,
      address,
      birthday,
      tels,
      businessCode,
      ...others
    }) => {
      const formattedName = name && `${name}`.trim();
      const formattedEmail = email && `${email}`.trim().toLowerCase();

      if (!customerId) {
        if (askName && !formattedName) {
          return intl.formatMessage({
            id: "name.can.not.be.empty",
            defaultMessage: "Name can not be empty",
          });
        }

        if (askTel && !phoneNumber && (!Array.isArray(tels) || !tels?.find((tel) => tel?.number))) {
          return intl.formatMessage({
            id: "phone.number.can.not.be.empty",
            defaultMessage: "Phone number can not be empty",
          });
        }

        if (askEmail && !formattedEmail)
          return intl.formatMessage({
            id: "please.leave.your.email.address",
            defaultMessage: "Please leave your email address",
          });

        if (!businessCode) {
          return intl.formatMessage({
            id: "please.select.customer.register.place",
            defaultMessage: "Please select customer register place",
          });
        }
      }

      if (phoneNumber?.valid === false || tels?.find((phoneNumber) => phoneNumber.valid === false)) {
        return intl.formatMessage({
          id: "please.enter.a.valid.phone.number",
          defaultMessage: "Please enter a valid phone number",
        });
      }

      if (formattedEmail && !isValidEmail(formattedEmail))
        return intl.formatMessage({
          id: "please.enter.a.valid.email.address",
          defaultMessage: "Please enter a valid email address",
        });

      return [
        OPERATION_GROUP_CUSTOMER,
        CUSTOMER_ACTION_PROFILE_SAVE,
        {
          ...others,
          businessCode,
          ...(customerId && { customerId }),
          ...(formattedName && { name: formattedName }),
          ...(phoneNumber && { phoneNumber }),
          ...(tels && { tels }),
          ...(birthday && { birthday }),
          ...(typeof remarks === "string" && { remarks }),
          ...(typeof address === "string" && { address }),
          ...(avatar && { avatar }),
          ...(typeof email === "string" && { email }),
          ...(editOrderId && { editOrderId }),
        },
      ];
    },
    [askName, askTel, askEmail]
  );

  return useActionCallback(onSubmit, callback, (request, response) => {
    const { name, phoneNumber, email, avatar } = request;
    const updates = (name ? 1 : 0) + (phoneNumber ? 1 : 0) + (email ? 1 : 0) + (avatar ? 1 : 0);

    toast.success(
      response.newCustomer
        ? intl.formatMessage({
            id: "new.account.created",
            defaultMessage: "New account created",
          })
        : updates > 1
        ? intl.formatMessage({
            id: "profile.updated",
            defaultMessage: "Profile updated",
          })
        : phoneNumber
        ? intl.formatMessage({
            id: "phone.number.updated",
            defaultMessage: "Phone number updated",
          })
        : email
        ? intl.formatMessage({
            id: "email.updated",
            defaultMessage: "Email updated",
          })
        : intl.formatMessage({
            id: "profile.updated",
            defaultMessage: "Profile updated",
          })
    );
  });
}

export default useCustomerProfileSave;
