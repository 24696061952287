import calculateUnitIndex from "./calculateUnitIndex.js";
import {
  PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORIES,
  PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_INDEX,
  PLACEHOLDER_ORDER_LINE_ITEM_ADD_ONS,
  PLACEHOLDER_ORDER_LINE_ITEM_COMPLETION_DATE,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CODE,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CONTENT,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NOTE,
  PLACEHOLDER_ORDER_LINE_ITEM_DETAILS,
  PLACEHOLDER_ORDER_LINE_ITEM_ID,
  PLACEHOLDER_ORDER_LINE_ITEM_INDEXES,
  PLACEHOLDER_ORDER_LINE_ITEM_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_NOTE,
  PLACEHOLDER_ORDER_LINE_ITEM_PCS_INDEX,
  PLACEHOLDER_ORDER_LINE_ITEM_PHOTO,
  PLACEHOLDER_ORDER_LINE_ITEM_QTY,
  PLACEHOLDER_ORDER_LINE_ITEM_SERVICE_OPTION_CODE,
  PLACEHOLDER_ORDER_LINE_ITEM_SERVICE_OPTION_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_SET_PCS_INDEX,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_CODE,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_DISCOUNT,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NAME,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NOTE,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_PRICE,
  PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTIONS,
  PLACEHOLDER_ORDER_LINE_ITEM_TOTAL,
  PLACEHOLDER_ORDER_LINE_ITEM_TOTAL_PCS_PER_SET,
  PLACEHOLDER_ORDER_LINE_ITEM_UNIT,
  PLACEHOLDER_ORDER_LINE_ITEM_UNIT_INDEX,
  PLACEHOLDER_ORDER_LINE_ITEM_UNIT_PRICE,
  PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_DETAIL,
  PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_SUB_SERVICE_OPTION,
  TEMPLATE_CONTENT_TYPE_ITEM,
} from "./constants/content.js";
import formatDate from "./formatDate.js";
import formatNumber from "./formatNumber.js";
import formatPhotoUrl from "./formatPhotoUrl.js";
import formatPrice from "./formatPrice.js";

export default function formatTemplateLineItems(
  { items, labels, currency, minimumOrder, minimumSurchargeAmount, serviceTypes, fixedQty, fixedAmount },
  realmId,
  dateFormat,
  currencyDisplay,
  placeholders,
  contentType
) {
  const lineItems = [];
  const emptyOrder = (items || []).length === 0;
  const showPrice = placeholders?.includes(PLACEHOLDER_ORDER_LINE_ITEM_TOTAL);

  if (emptyOrder) {
    if (typeof fixedQty === "number" || typeof fixedAmount === "number") {
      lineItems.push({
        // Product fields
        [PLACEHOLDER_ORDER_LINE_ITEM_NAME]: "Total service",
        [PLACEHOLDER_ORDER_LINE_ITEM_QTY]: fixedQty,
        [PLACEHOLDER_ORDER_LINE_ITEM_TOTAL]: formatNumber(fixedAmount || 0, currency, true, currencyDisplay),
      });
    } else {
      serviceTypes?.forEach((serviceType) => {
        lineItems.push({
          [PLACEHOLDER_ORDER_LINE_ITEM_NAME]: `${serviceType.title || ""}${
            typeof serviceType.minimumOrder === "number"
              ? `(Minimum charge for ${serviceType.title || ""} is ${formatNumber(
                  serviceType.minimumOrder,
                  currency,
                  true,
                  currencyDisplay
                )})`
              : ""
          }`,
          ...(serviceType.photoId && {
            [PLACEHOLDER_ORDER_LINE_ITEM_PHOTO]: {
              url: formatPhotoUrl(serviceType.photoId),
            },
          }),
          [PLACEHOLDER_ORDER_LINE_ITEM_TOTAL]: formatNumber(serviceType.minimumOrder, currency, true, currencyDisplay),
        });
      });
    }
  } else {
    const isLabel = contentType === TEMPLATE_CONTENT_TYPE_ITEM;

    (isLabel
      ? labels
          ?.map((labelId, labelIndex) => {
            const item = items?.find(({ labelIndexes }) => labelIndexes?.includes(labelIndex + 1));
            if (item) {
              return {
                ...item,
                labelId,
                labelIndex: labelIndex + 1,
              };
            }
          })
          .filter(Boolean)
      : items
    )?.forEach((item) => {
      const {
        labelId,
        labelIndex,
        lineTotal,
        option,
        optionCode,
        product,
        subType,
        qty,
        unit,
        unitPrice,
        photoId,
        measure,
        subOptions,
        selectSteps,
        accessories,
        addons,
        addonQty,
        note,
        notes,
        unitQty,
        unitLabel,
        pcs,
        date,
        labelIndexes,
      } = item;
      const itemNote = (Array.isArray(notes) && labelIndex ? notes[labelIndexes?.indexOf(labelIndex)] : null) ?? note;
      const lineItem = {
        [PLACEHOLDER_ORDER_LINE_ITEM_NAME]: `${product || ""}${subType ? ` - ${subType}` : ""}${
          measure ? ` (${measure})` : ""
        }${unitQty && unitLabel ? ` (${unitQty} ${unitLabel})` : ""}${
          Number.isInteger(pcs) ? ` (Total ${pcs} pcs)` : ""
        }`,
        [PLACEHOLDER_ORDER_LINE_ITEM_TOTAL]: formatNumber(lineTotal, currency, true, currencyDisplay),
        [PLACEHOLDER_ORDER_LINE_ITEM_QTY]: qty,
        [PLACEHOLDER_ORDER_LINE_ITEM_UNIT]: unit,
        [PLACEHOLDER_ORDER_LINE_ITEM_UNIT_PRICE]: formatNumber(unitPrice, currency, true, currencyDisplay),
        ...(optionCode && {
          [PLACEHOLDER_ORDER_LINE_ITEM_SERVICE_OPTION_CODE]: optionCode,
        }),
        ...(photoId && {
          [PLACEHOLDER_ORDER_LINE_ITEM_PHOTO]: {
            url: formatPhotoUrl(photoId, realmId),
          },
        }),
        ...(date && {
          [PLACEHOLDER_ORDER_LINE_ITEM_COMPLETION_DATE]: formatDate(date, dateFormat),
        }),
        ...(Array.isArray(labelIndexes) && {
          [PLACEHOLDER_ORDER_LINE_ITEM_INDEXES]: labelIndexes.map((index) => `#${index}`).join("/"),
        }),
      };

      // Item label
      if (labelIndex) {
        lineItem[PLACEHOLDER_ORDER_LINE_ITEM_ID] = labelId;
        lineItem[PLACEHOLDER_ORDER_LINE_ITEM_PCS_INDEX] = labelIndex; // Important, label rendering using this index to determine which the line item needs to generate label

        const { unitIndex, unitTotalPcs, unitPcsIndex } = calculateUnitIndex(items, labelIndex);

        lineItem[PLACEHOLDER_ORDER_LINE_ITEM_UNIT_INDEX] = unitIndex;
        lineItem[PLACEHOLDER_ORDER_LINE_ITEM_TOTAL_PCS_PER_SET] = unitTotalPcs;
        lineItem[PLACEHOLDER_ORDER_LINE_ITEM_SET_PCS_INDEX] = unitPcsIndex;
      }

      // Add-ons
      if (addons?.length > 0) {
        const displayAddons = [];

        addons.forEach(({ title, code, surcharge }) => {
          const price = typeof surcharge === "number" ? surcharge : 0;
          const displayTitle = title || code;
          const index = displayAddons.findIndex((other) => other.title === displayTitle && other.price === price);

          if (index >= 0) {
            displayAddons[index].qty++;
          } else {
            displayAddons.push({
              title: displayTitle,
              qty: typeof addonQty === "number" ? addonQty : 1,
              ...(showPrice && {
                price,
              }),
            });
          }
        });

        lineItem[PLACEHOLDER_ORDER_LINE_ITEM_ADD_ONS] = displayAddons
          .sort((a, b) => formatPrice(b.qty * b.price) - formatPrice(a.qty * a.price))
          .map(({ title, qty, price }) => `Add-on: ${title} x ${qty} (${formatPrice(price * qty)})`)
          .join("\n");
      }

      // Accessories
      if (accessories?.length > 0) {
        lineItem[PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORIES] = `Accessory: ${accessories
          .map(({ title, qty }) => `${title} x ${qty}`)
          .join("; ")}`;
      }

      // Notes
      let itemNotes = itemNote ? [itemNote] : [];

      if (Array.isArray(selectSteps)) {
        selectSteps.forEach(({ code, title, value }) => {
          if (Array.isArray(value)) {
            let noteMap = {},
              codeMap = {};

            value.forEach(({ note, code }) => {
              if (note && code) {
                const noteKey = `${note}`.trim().toLowerCase();
                noteMap[noteKey] = note;
                codeMap[code] = noteKey;
              }
            });

            let notes = Object.values(codeMap);
            let codes = Object.keys(codeMap);
            // Prefilled notes
            if (notes.length > 0) {
              notes = notes.filter((note, index) => notes.indexOf(note) === index);
              const stepNotes = notes.map(
                (noteKey) => `${codes.filter((code) => codeMap[code] === noteKey).join("/")}: ${noteMap[noteKey]}`
              );
              itemNotes.push(`** Attention on ${title || code}`);
              stepNotes.forEach((note) => itemNotes.push(note));
            }
          }
        });
      }

      if (itemNotes.some(Boolean)) {
        lineItem[PLACEHOLDER_ORDER_LINE_ITEM_NOTE] = itemNotes.filter(Boolean).join("\n");
      }

      // Service option
      if (option) {
        lineItem[PLACEHOLDER_ORDER_LINE_ITEM_SERVICE_OPTION_NAME] = option;
      }

      if (
        subOptions?.length > 0 &&
        placeholders?.some((placeholder) =>
          `${placeholder}`.includes(PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_SUB_SERVICE_OPTION)
        )
      ) {
        lineItem[PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTIONS] = subOptions.map(
          ({ title, code, price, discountAmount, note }) => {
            return {
              [PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NAME]: title,
              ...(code &&
                placeholders?.includes(PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_CODE) && {
                  [PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_CODE]: code,
                }),
              ...(typeof price === "number" &&
                placeholders?.includes(PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_PRICE) && {
                  [PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_PRICE]: formatNumber(
                    price,
                    currency,
                    false,
                    currencyDisplay
                  ),
                }),
              ...(typeof discountAmount === "number" &&
                placeholders?.includes(PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_DISCOUNT) && {
                  [PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_DISCOUNT]: formatNumber(
                    discountAmount,
                    currency,
                    false,
                    currencyDisplay
                  ),
                }),
              ...(note &&
                placeholders?.includes(PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NOTE) && {
                  [PLACEHOLDER_ORDER_LINE_ITEM_SUB_SERVICE_OPTION_NOTE]: note,
                }),
            };
          }
        );
      }

      // Select steps
      if (
        selectSteps?.length > 0 &&
        placeholders?.some((placeholder) => `${placeholder}`.includes(PLACEHOLDER_SUB_DATA_SET_LINE_ITEM_DETAIL))
      ) {
        lineItem[PLACEHOLDER_ORDER_LINE_ITEM_DETAILS] = (selectSteps || []).map(({ title, code, value, note }) => {
          const content =
            typeof value === "string"
              ? value
              : Array.isArray(value)
              ? value.map(({ title, code }) => `${title || code}`).join(", ")
              : null;

          return {
            [PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NAME]: title,
            ...(code &&
              placeholders?.includes(PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CODE) && {
                [PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CODE]: code,
              }),
            ...(content &&
              placeholders?.includes(PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CONTENT) && {
                [PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CONTENT]: content,
              }),
            ...(note &&
              placeholders?.includes(PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_CONTENT) && {
                [PLACEHOLDER_ORDER_LINE_ITEM_DETAIL_NOTE]: note,
              }),
          };
        });
      }

      lineItems.push(lineItem);

      if (isLabel && Array.isArray(accessories)) {
        let accessoryIndex = 0;

        accessories.forEach(({ qty, title }) => {
          Array.from({ length: Number.isInteger(qty) ? qty : 0 }, (_, index) => {
            accessoryIndex++;

            lineItems.push({
              ...lineItem,
              [PLACEHOLDER_ORDER_LINE_ITEM_ACCESSORY_INDEX]: accessoryIndex,
              [PLACEHOLDER_ORDER_LINE_ITEM_NAME]: `Accessory: ${title} x ${qty}${
                qty > 1 ? `(${index + 1}/${qty})` : ""
              }`,
              [PLACEHOLDER_ORDER_LINE_ITEM_ID]: null,
            });
          });
        });
      }
    });

    if (Array.isArray(serviceTypes)) {
      serviceTypes.forEach((serviceType) => {
        if (typeof serviceType.minCharge === "number" && serviceType.minCharge > 0) {
          lineItems.push({
            [PLACEHOLDER_ORDER_LINE_ITEM_NAME]: `${serviceType.title || ""} difference to minimum${
              typeof serviceType.minimumOrder === "number"
                ? ` (Minimum charge for ${serviceType.title} is ${formatNumber(
                    serviceType.minimumOrder,
                    currency,
                    true,
                    currencyDisplay
                  )})`
                : ""
            }`,
            [PLACEHOLDER_ORDER_LINE_ITEM_TOTAL]: formatNumber(serviceType.minCharge, currency, true, currencyDisplay),
          });
        }
      });
    }
  }

  if (minimumSurchargeAmount > 0) {
    lineItems.push({
      [PLACEHOLDER_ORDER_LINE_ITEM_NAME]: `Difference to minimum (Order minimum charge is ${formatNumber(
        minimumOrder,
        currency,
        true,
        currencyDisplay
      )})`,
      [PLACEHOLDER_ORDER_LINE_ITEM_TOTAL]: formatNumber(minimumSurchargeAmount, currency, true, currencyDisplay),
    });
  }

  return lineItems;
}
