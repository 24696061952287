"use client";

import {
  AccessProvider,
  AsyncUploadProvider,
  ChatProvider,
  FeedbackProvider,
  KeyboardProvider,
  KVStorageProvider,
  LicenseProvider,
  PrintingProvider,
  RealmProvider,
  ServerFunctionProvider,
  useClientHasNetwork,
  useClientInstallId,
  useClientInstallIdSetter,
  useClientRegisterDeviceSetter,
  useClientStateSetter,
  useClientType,
  UserProvider,
} from "@easybiz/shell";
import { ADMIN_ACTION_DEVICE_SIGN_OUT, OPERATION_GROUP_ADMIN } from "@easybiz/utils";
import { useCallback, useEffect, useState } from "react";
import ChatConversationMonitor from "./ChatConversationMonitor";
import ChatUnreadMonitor from "./ChatUnreadMonitor";
import ClientDateMonitor from "./ClientDateMonitor";
import ConnectDeviceMonitor from "./ConnectDeviceMonitor";
import DeviceRegister from "./DeviceRegister";
import LoggedOutDeviceAutoSignIn from "./LoggedOutDeviceAutoSignIn";

export default function WorkflowApp({
  children,
  localStorage,
  uploadStorage,
  getDeviceInfo,
  toaster,
  // Printing
  ESCEncoder,
  usbIO,
  bluetoothIO,
  connectPrinter,
  // Firebase: Installations
  getId,
  getInstallations,
  onIdChange,
  // Firebase: Auth
  onIdTokenChanged,
  signInWithCustomToken,
  signOut,
  getAuth,
  // Firebase: Firestore
  onSnapshot,
  doc,
  getFirestore,
  deleteDoc,
  // Firebase: Functions
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
}) {
  const client = useClientType();
  const installId = useClientInstallId();
  const hasNetwork = useClientHasNetwork();
  const setInstallId = useClientInstallIdSetter();
  const setAppState = useClientStateSetter();
  const setRegisterDevice = useClientRegisterDeviceSetter();
  const [user, setUser] = useState();
  const [realm, setRealm] = useState();
  const [license, setLicenseDoc] = useState();
  const realmId = user?.realmId;
  const uid = user?.uid;
  const businessCode = user?.businessCode;
  const licenseId = businessCode || uid;

  useEffect(() => {
    if (!installId) {
      getId(getInstallations()).then(setInstallId);

      return onIdChange(getInstallations(), setInstallId);
    }
  }, [installId]);

  useEffect(() => {
    return onIdTokenChanged(getAuth(), async (user) => {
      if (user) {
        // Sign in
        const { claims } = await user.getIdTokenResult();

        if (claims.installId) {
          setInstallId(claims.installId);
        }

        setUser({
          ...claims.realmConfig,
          ...(claims.installId && { installId: claims.installId }),
          ...(claims.businessCode && { businessCode: claims.businessCode }),
          ...(claims.multiStation && { multiStation: claims.multiStation }),
          ...(claims.storeLocation && { storeLocation: claims.storeLocation }),
          ...(claims.clients && { clients: claims.clients }),
          ...(claims.ebAccess && { ebAccess: true }),
          ...(claims.realmMaster && { realmMaster: true }),
          ...(claims.connectDeviceId && { connectDeviceId: claims.connectDeviceId }),
          ...(claims.staffId && {
            checkInStaff: {
              id: claims.staffId,
              name: claims.staffName || "",
              ...(claims.clockInTime && { clockInTime: claims.clockInTime }),
            },
          }),
          permissions: claims.permissions,
          debugMode: claims.ebAccess,
          realmId: claims.realmId,
          authTime: claims.auth_time,
          ...(user.photoURL && { avatarUrl: user.photoURL }),
          name: user.displayName || null,
          email: user.email || null,
          emailVerified: user.emailVerified,
          uid: user.uid,
        });
      } else {
        setUser({});
      }
    });
  }, []);

  // Monitor realm
  useEffect(() => {
    if (realmId) {
      return onSnapshot(doc(getFirestore(), `realms/${realmId}`), (onSnapshot) =>
        setRealm({ ...onSnapshot.data(), realmId: onSnapshot.id })
      );
    }
  }, [realmId]);

  // Monitor license
  useEffect(() => {
    if (realmId && licenseId) {
      return onSnapshot(doc(getFirestore(), `billings/${realmId}/licences/${licenseId}`), (onSnapshot) =>
        setLicenseDoc(onSnapshot.data() || {})
      );
    }
  }, [realmId, licenseId]);

  // Monitor device
  useEffect(() => {
    if (installId && client && realmId) {
      return onSnapshot(
        doc(getFirestore(), `realms/${realmId}/devices/${client}/installs/${installId}`),
        setRegisterDevice
      );
    }
  }, [installId, realmId, client]);

  const callable = useCallback((params) => {
    const functions = getFunctions();

    if (process.env.NODE_ENV === "development") {
      connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    }

    return httpsCallable(
      functions,
      process.env.NEXT_PUBLIC_VERCEL_ENV === "preview" ? "stagingOperationCreate" : "operationCreate"
    )(params);
  }, []);

  const onRegistered = useCallback(({ signInToken, notice, intercomUser }) => {
    if (signInToken) {
      signInWithCustomToken(getAuth(), signInToken);
    }

    if (notice) {
      setAppState({ notice });
    }

    // TODO: REMOVE INTERCOM
    if (intercomUser && typeof window !== "undefined" && window.Intercom) {
      window.Intercom("boot", {
        ...intercomUser,
        custom_launcher_selector: `#intercomLauncher`,
        hide_default_launcher: true,
      });
    }
  }, []);

  const onSignOut = useCallback(() => {
    return new Promise((resolve) => {
      if (hasNetwork) {
        callable({
          group: OPERATION_GROUP_ADMIN,
          type: ADMIN_ACTION_DEVICE_SIGN_OUT,
          client,
        })
          .then(resolve)
          .catch(resolve);
      } else {
        resolve();
      }
    }).then(() => signOut(getAuth()));
  }, []);

  return (
    <UserProvider user={user} setUser={setUser}>
      <LicenseProvider license={license}>
        <RealmProvider realm={realm}>
          <AccessProvider>
            <ServerFunctionProvider callable={callable}>
              <PrintingProvider
                ESCEncoder={ESCEncoder}
                usbIO={usbIO}
                bluetoothIO={bluetoothIO}
                connectPrinter={connectPrinter}
              >
                <FeedbackProvider toaster={toaster}>
                  <AsyncUploadProvider uploadStorage={uploadStorage}>
                    <KVStorageProvider localStorage={localStorage}>
                      <KeyboardProvider>
                        <ChatProvider>
                          {children}

                          <ChatConversationMonitor onSnapshot={onSnapshot} doc={doc} getFirestore={getFirestore} />
                          <ChatUnreadMonitor onSnapshot={onSnapshot} doc={doc} getFirestore={getFirestore} />
                        </ChatProvider>
                      </KeyboardProvider>
                    </KVStorageProvider>
                  </AsyncUploadProvider>

                  <DeviceRegister
                    getDeviceInfo={getDeviceInfo}
                    onSignOut={onSignOut}
                    onRegistered={onRegistered}
                    betaRelease={Boolean(process.env.NEXT_PUBLIC_partial_release)}
                  />
                  <ClientDateMonitor />
                  <ConnectDeviceMonitor onSnapshot={onSnapshot} doc={doc} getFirestore={getFirestore} />
                  <LoggedOutDeviceAutoSignIn
                    onSnapshot={onSnapshot}
                    doc={doc}
                    getFirestore={getFirestore}
                    signInWithCustomToken={signInWithCustomToken}
                    getAuth={getAuth}
                    deleteDoc={deleteDoc}
                  />
                </FeedbackProvider>
              </PrintingProvider>
            </ServerFunctionProvider>
          </AccessProvider>
        </RealmProvider>
      </LicenseProvider>
    </UserProvider>
  );
}
