import { useAppDaySales } from "@easybiz/web-firebase";
import { Spin, Tag } from "antd";
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

export default ({ date, businessCode, orderCount }) => {
  const report = useAppDaySales(date, businessCode);

  if (report === undefined) {
    return <Spin spinning />;
  } else if (report) {
    const { net, paid, bal, order } = report;

    return orderCount ? (
      order > 0 ? (
        <Tag color="processing">
          <FormattedMessage id="count.x.orders" defaultMessage={'{count} orders'} values={{
            count: order
          }} />
        </Tag>
      ) : null
    ) : (
      <>
        <Tag color="processing">
          <FormattedNumber value={net} minimumFractionDigits={2} />
        </Tag>
        {paid !== 0 && (
          <Tag color={"success"}>
            <FormattedNumber value={paid} minimumFractionDigits={2} />
          </Tag>
        )}
        {bal > 0 && (
          <Tag color="error">
            <FormattedNumber value={bal} minimumFractionDigits={2} />
          </Tag>
        )}
      </>
    );
  } else {
    return null;
  }
};
