"use client";

import { Component } from "react";

function getErrorHash(error, info) {
  const data = `${error.message}-${error.stack}-${info.componentStack}`;
  let hash = 0;
  for (let i = 0; i < data.length; i++) {
    const char = data.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }
  return `${Math.abs(hash)}`;
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);

    const { doc, setDoc, getFirestore, increment } = this.props;
    const errorHash = getErrorHash(error, info);
    const reportRef = doc(getFirestore(), `errors/${errorHash}`);

    setDoc(
      reportRef,
      {
        message: error.message || null,
        name: error.name || null,
        stack: error.stack || null,
        componentStack: info.componentStack || null,
        timestamp: new Date().toISOString(),
        userAgent: navigator.userAgent || null,
        count: increment(1),
      },
      {
        merge: true,
      }
    );

    this.setState({ reportId: reportRef.id });
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#dc2626"
            style={{
              width: 60,
              height: 60,
            }}
          >
            <path
              fillRule="evenodd"
              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
              clipRule="evenodd"
            />
          </svg>

          <p style={{ textAlign: "center", fontSize: 24 }}>{this.props.title || "Oops! Something went wrong"}</p>
          <div
            style={{
              marginTop: 16,
              marginBottom: 16,
              borderLeftWidth: 4,
              borderLeftColor: "#b91c1c",
              backgroundColor: "#fef2f2",
              padding: 16,
              maxWidth: 480,
            }}
          >
            <p
              style={{
                fontSize: 14,
                lineHeight: "20px",
                color: "#b91c1c",
              }}
            >
              We’re aware of this issue and our team is already working on a fix. Please try reloading the app, and if
              the problem persists, feel free to contact our support team. Thank you for your patience!
            </p>
            <br />
            <a
              href="#"
              style={{
                fontWeight: 500,
                color: "#b91c1c",
                textDecoration: "underline",
              }}
            >
              Error ID: {this.state.reportId}
            </a>
          </div>
          <button
            role="button"
            onClick={() => window.location.reload(true)}
            style={{
              borderRadius: "0.375rem",
              backgroundColor: "white",
              paddingLeft: 14,
              paddingRight: 14,
              paddingTop: 10,
              paddingBottom: 10,
              fontSize: "0.875rem",
              lineHeight: "1.25rem",
              fontWeight: 600,
              color: "color: rgb(17 24 39)",
              filter: "drop-shadow(0 1px 1px rgb(0 0 0 / 0.05))",
              outlineWidth: 1,
              outlineColor: "#d1d5db",
              cursor: "pointer",
            }}
          >
            Reload
          </button>
          <br />
          <br />
          <br />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
