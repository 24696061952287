import { CalendarOutlined, CheckOutlined } from "@ant-design/icons";
import { DateDisplay, useCustomerForm } from "@easybiz/component";
import { CLIENT_POS } from "@easybiz/utils";
import { useCustomerDoc } from "@easybiz/web-firebase";
import { Alert, Button, Checkbox, DatePicker, Form, Input, Space } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import ContactsInput from "../ContactsInput";
import CustomerSourceSelecter from "../CustomerSourceSelecter";
import BirthdayInputDialog from "./BirthdayInputDialog";
import { useClientType, useRealmPOSSettings, useUserBusinessCode } from "@easybiz/shell";

export default ({ customerId, editOrderId, customerMode, onSaved }) => {
  const clientType = useClientType();
  const customerDoc = useCustomerDoc(customerId);
  const businessCode = useUserBusinessCode();
  const { freeAddress } = useRealmPOSSettings();
  const [birthdayVisible, setBirthdayVisible] = useState(false);
  const {
    profile,
    onChange,
    name,
    tel,
    email,
    nameChangeAlert,
    birthday,
    whereFindUs,
    privateNote,
    submitButton,
    registerPlace,
    loading,
    onSubmit,
  } = useCustomerForm(customerDoc, onSaved, editOrderId);

  return (
    <>
      <Form layout="horizontal" labelCol={{ span: 5 }} wrapperCol={{ span: 18 }}>
        <Form.Item label={name.label}>
          <Space direction="vertical" className="full-width">
            <Input
              size="large"
              autoFocus
              disabled={loading}
              value={profile?.name}
              onChange={(e) => onChange({ name: e.target.value })}
            />
            {nameChangeAlert && <Alert type="warning" showIcon={false} message={nameChangeAlert} />}
          </Space>
        </Form.Item>
        <Form.Item label={tel.label}>
          <ContactsInput disabled={loading} tels={profile?.tels} onChange={(tels) => onChange({ tels })} />
        </Form.Item>
        <Form.Item label={email.label}>
          <Input
            size="large"
            type="email"
            disabled={loading}
            placeholder={email.placeholder}
            value={profile?.email}
            onChange={(e) => onChange({ email: e.target.value.trim() })}
            suffix={
              email.verified && (
                <span style={{ color: "green" }}>
                  <CheckOutlined style={{ color: "green" }} />{" "}
                  <FormattedMessage tagName="span" defaultMessage="Verified" />
                </span>
              )
            }
          />
        </Form.Item>
        {businessCode && freeAddress && (
          <Form.Item label={<FormattedMessage tagName="span" defaultMessage="Address" />}>
            <Input
              size="large"
              value={profile?.address}
              disabled={loading}
              onChange={(e) => onChange({ address: e.target.value })}
            />
          </Form.Item>
        )}
        {!customerMode && (
          <Form.Item key="remark" label={privateNote.label}>
            <Input.TextArea
              size="large"
              disabled={loading}
              value={profile?.remarks}
              onChange={(e) => onChange({ remarks: e.target.value })}
              placeholder={privateNote.placeholder}
            />
          </Form.Item>
        )}
        <Form.Item label={birthday.label} span={2}>
          {clientType === CLIENT_POS ? (
            <Button
              size="large"
              disabled={loading}
              onClick={() => setBirthdayVisible(true)}
              icon={<CalendarOutlined />}
            >
              {profile?.birthday ? (
                <span>
                  <DateDisplay date={profile?.birthday} />
                </span>
              ) : (
                <FormattedMessage tagName="span" defaultMessage="Set" />
              )}
            </Button>
          ) : (
            <DatePicker
              disabled={loading}
              value={profile?.birthday && dayjs(profile?.birthday)}
              onChange={(date) => onChange({ birthday: date.format("YYYY-MM-DD") })}
            />
          )}
        </Form.Item>
        {whereFindUs && (
          <Form.Item label={whereFindUs.label} span={2}>
            <Space wrap size={"middle"}>
              {whereFindUs.options.map((option) => {
                const checked = profile?.knownSource === option;

                return (
                  <Checkbox
                    key={option}
                    value={option}
                    checked={checked}
                    disabled={loading}
                    onChange={() => onChange({ knownSource: checked ? null : option })}
                  >
                    {option}
                  </Checkbox>
                );
              })}
            </Space>
          </Form.Item>
        )}
        {registerPlace && (
          <Form.Item label={registerPlace.label}>
            <CustomerSourceSelecter
              disabled={loading}
              select={profile?.businessCode}
              onSelect={(businessCode) => onChange({ ...profile, businessCode })}
            />
          </Form.Item>
        )}
      </Form>
      <div className="padding-right">
        <Button type="primary" block onClick={onSubmit} loading={loading}>
          {submitButton}
        </Button>
      </div>
      <BirthdayInputDialog
        birthday={profile?.birthday}
        open={birthdayVisible}
        onCancel={() => setBirthdayVisible(false)}
        onSave={(birthday) => {
          onChange({ birthday });
          setBirthdayVisible(false);
        }}
      />
    </>
  );
};
