"use client";

import { createContext, useContext, useMemo } from "react";

const UserContext = createContext();

export function UserProvider({ children, user, updateUser, setUser }) {
  const context = useMemo(() => {
    return {
      user,
      updateUser,
      setUser,
      operator: user
        ? {
            id: user.customerId || user.uid,
            name: (user.checkInStaff ? user.checkInStaff.name : user.name) || "",
          }
        : null,
      isPendingSignIn: user === false || (user && !user?.uid),
    };
  }, [user, updateUser]);

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
}

export const useUser = () => useContext(UserContext).user;
export const useUserUpdate = () => useContext(UserContext).updateUser;
export const useUserSet = () => useContext(UserContext).setUser;
export const useUserIDName = () => useContext(UserContext).operator;
export const useUserIsPendingSignIn = () => useContext(UserContext).isPendingSignIn;
// User helper hooks
export const useUserRealmId = () => useContext(UserContext).user?.realmId;
export const useUserBusinessCode = () => useContext(UserContext).user?.businessCode;
export const useUserGeoCenter = () => useContext(UserContext).user?.geoCenter;
export const useUserIsTestMode = () => useContext(UserContext).user?.isTestMode;
export const useUserStripeAccount = () => useContext(UserContext).user?.stripeAccount;
export const useUserCountryCode = () => useContext(UserContext).user?.countryCode;
export const useUserDialCode = () => useContext(UserContext).user?.dialCode;
export const useUserCurrency = () => useContext(UserContext).user?.currency;
export const useUserCityName = () => useContext(UserContext).user?.city;
export const useUserUID = () => useContext(UserContext).user?.uid;
export const useUserName = () => useContext(UserContext).user?.name;
export const useUserEmail = () => useContext(UserContext).user?.email;
export const useUserCustomerId = () => useContext(UserContext).user?.customerId;
export const useUserIsRealmMaster = () => useContext(UserContext).user?.realmMaster;
export const useUserDebugMode = () => useContext(UserContext).user?.ebAccess;
export const useUserTestMode = () => useContext(UserContext).user?.isTestMode;
export const useUserPermissions = () => useContext(UserContext).user?.permissions;
export const useUserPairedDeviceId = () => useContext(UserContext).user?.connectDeviceId;
export const useUserAuthTime = () => useContext(UserContext).user?.authTime;
export const useUserClockInIDName = () => useContext(UserContext).user?.checkInStaff;

// TODO: FETCH FROM TOKEN
export const useUserStorageHost = () => process.env.REACT_APP_storage_host || process.env.NEXT_PUBLIC_storage_host;
export const useUserImageHost = () => process.env.REACT_APP_image_host || process.env.NEXT_PUBLIC_image_host;
export const useUserGoogleMapKey = () => process.env.REACT_APP_google_map_pk || process.env.NEXT_PUBLIC_google_map_pk;
